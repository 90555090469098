<template>
    <div class="cooperationForPhone">
        <img src="../assets/images/Cooperation/u1989_div.png" width="100%"/>
        <div class="top-tab-dv ft12">
            <div
                class="top-tab-dv-item"
                :class="active == 0 ? 'active' : ''"
                @click="checkPage(0)"
            >
                {{ $t('lang.AllPartner') }}
            </div>
            <div
                class="top-tab-dv-item"
                :class="active == 1 ? 'active' : ''"
                @click="checkPage(1)"
            >
                {{ $t('lang.PoultryEnterprise') }}
            </div>
            <div
                class="top-tab-dv-item"
                :class="active == 2 ? 'active' : ''"
                @click="checkPage(2)"
            >
                {{ $t('lang.PigEnterprise') }}
            </div>
            <div
                class="top-tab-dv-item"
                :class="active == 3 ? 'active' : ''"
                @click="checkPage(3)"
            >
                {{ $t('lang.ConvergedEnterprise') }}
            </div>
            <div
                class="top-tab-dv-item"
                :class="active == 4 ? 'active' : ''"
                @click="checkPage(4)"
            >
                {{ $t('lang.UniversityEnterpriseCooperation') }}
                <!--   页面显示University cooperation 缺少 Enterprise  -->
            </div>
        </div>
        <div style="min-height:400px">
            <div class="device-img-dv">
                <div
                    class="device-img-item-dv"
                    v-for="(item, index) in imgData"
                    :key="index"
                >
                    <img :src="item"/>
                </div>
            </div>
        </div>

        <AddressForPhone></AddressForPhone>
    </div>
</template>
<script>
import AddressForPhone from "./addressForPhone";

export default {
    name: "cooperationForPhone",
    components: {
        AddressForPhone,
    },
    data() {
        return {
            active: 0,
            imgData: [],
            data0: [
                require("../assets/images/Cooperation/logo_u1992-3.svg"),
                require("../assets/images/Cooperation/logo_u1992-4.svg"),
                require("../assets/images/Cooperation/logo_u1992-15.svg"),
                require("../assets/images/Cooperation/logo_u1992-17.svg"),
                require("../assets/images/Cooperation/logo_u1992-26.svg"),
                require("../assets/images/Cooperation/logo_u1992-2.svg"),
                require("../assets/images/Cooperation/logo_u1992-5.svg"),
                require("../assets/images/Cooperation/logo_u1992-6.svg"),
                require("../assets/images/Cooperation/logo_u1992-7.svg"),
                require("../assets/images/Cooperation/logo_u1992-9.svg"),
                require("../assets/images/Cooperation/logo_u1992-18.svg"),
                require("../assets/images/Cooperation/logo_u1992-19.svg"),
                require("../assets/images/Cooperation/logo_u1992-20.svg"),
                require("../assets/images/Cooperation/logo_u1992-21.svg"),
                require("../assets/images/Cooperation/logo_u1992-22.svg"),
                require("../assets/images/Cooperation/logo_u1992-23.svg"),
                require("../assets/images/Cooperation/logo_u1992-24.svg"),
                require("../assets/images/Cooperation/logo_u1992-25.svg"),
                require("../assets/images/Cooperation/logo_u1992-0.svg"),
                require("../assets/images/Cooperation/logo_u1992-1.svg"),
                require("../assets/images/Cooperation/logo_u1992-8.svg"),
                require("../assets/images/Cooperation/logo_u1992-10.svg"),
                require("../assets/images/Cooperation/logo_u1992-11.svg"),
                require("../assets/images/Cooperation/logo_u1992-12.svg"),
                require("../assets/images/Cooperation/logo_u1992-13.svg"),
                require("../assets/images/Cooperation/logo_u1992-14.svg"),
                require("../assets/images/Cooperation/logo_u1992-16.svg"),
                require("../assets/images/Cooperation/logo_u1992-27.svg"),
            ],
            data1: [
                require("../assets/images/Cooperation/logo_u1992-3.svg"),
                require("../assets/images/Cooperation/logo_u1992-4.svg"),
                require("../assets/images/Cooperation/logo_u1992-15.svg"),
                require("../assets/images/Cooperation/logo_u1992-17.svg"),
                require("../assets/images/Cooperation/logo_u1992-26.svg"),
                require("../assets/images/Cooperation/logo_u1992-29.png"),
                require("../assets/images/Cooperation/logo_u1992-30.png"),
                require("../assets/images/Cooperation/logo_u1992-31.png"),
                require("../assets/images/Cooperation/logo_u1992-32.png"),
                require("../assets/images/Cooperation/logo_u1992-33.png"),
                require("../assets/images/Cooperation/logo_u1992-34.png"),
            ],
            data2: [
                require("../assets/images/Cooperation/logo_u1992-2.svg"),
                require("../assets/images/Cooperation/logo_u1992-5.svg"),
                require("../assets/images/Cooperation/logo_u1992-6.svg"),
                require("../assets/images/Cooperation/logo_u1992-7.svg"),
                require("../assets/images/Cooperation/logo_u1992-9.svg"),
                require("../assets/images/Cooperation/logo_u1992-18.svg"),
                require("../assets/images/Cooperation/logo_u1992-19.svg"),
                require("../assets/images/Cooperation/logo_u1992-20.svg"),
                require("../assets/images/Cooperation/logo_u1992-21.svg"),
                require("../assets/images/Cooperation/logo_u1992-22.svg"),
                require("../assets/images/Cooperation/logo_u1992-23.svg"),
                require("../assets/images/Cooperation/logo_u1992-24.svg"),
                require("../assets/images/Cooperation/logo_u1992-25.svg"),
            ],
            data3: [
                require("../assets/images/Cooperation/logo_u1992-0.svg"),
                require("../assets/images/Cooperation/logo_u1992-1.svg"),
                require("../assets/images/Cooperation/logo_u1992-8.svg"),
                require("../assets/images/Cooperation/logo_u1992-10.svg"),
                require("../assets/images/Cooperation/logo_u1992-11.svg"),
            ],
            data4: [
                require("../assets/images/Cooperation/logo_u1992-12.svg"),
                require("../assets/images/Cooperation/logo_u1992-13.svg"),
                require("../assets/images/Cooperation/logo_u1992-14.svg"),
                require("../assets/images/Cooperation/logo_u1992-16.svg"),
                require("../assets/images/Cooperation/logo_u1992-27.svg"),
                require("../assets/images/Cooperation/logo_u1992-28.png"),
            ],
        };
    },
    mounted() {
        this.imgData = this.data0;
    },
    methods: {
        checkPage(index) {
            this.active = index;
            switch (index) {
                case 0:
                    this.imgData = this.data0;
                    break;
                case 1:
                    this.imgData = this.data1;
                    break;
                case 2:
                    this.imgData = this.data2;
                    break;
                case 3:
                    this.imgData = this.data3;
                    break;
                case 4:
                    this.imgData = this.data4;
                    break;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.top-tab-dv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #fff;
    text-align: center;
    height: 50px;
    background: rgba($color: #000000, $alpha: 1);
    box-sizing: border-box;
    border-top: solid 1px #444444;
    font-weight: 700;
    width: 100%;
    margin-top: -5px;
    font-size: 0.6rem;
}

.top-tab-dv-item {
    width: 33%;
    border: solid 1px #444444;
    display: flex;
    justify-content: center;
    align-items: center;
}

.active {
    color: #a0f31e;
    border-bottom: solid 2px #a0f31e;
}

.device-img-dv {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 90%;
    margin: 20px auto;
}

.device-img-item-dv {
    position: relative;
    width: 22%;
    margin-left: 2%;
    font-size: 0.8rem;
    text-align: center;
    margin-top: 10px;

    img {
        width: 100%;
    }
}
</style>
